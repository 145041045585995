import React from 'react';
import yaml from 'js-yaml';
// renderers is imported separately so Markdoc isn't sent to the client
import Markdoc, {renderers} from '@markdoc/markdoc'

import {getSchema, defaultObject} from '/vercel/path0/node_modules/@markdoc/next.js/src/runtime.js';
/**
 * Schema is imported like this so end-user's code is compiled using build-in babel/webpack configs.
 * This enables typescript/ESnext support
 */
const config = {};
import * as tags from '/vercel/path0/markdoc/tags.js'
import * as nodes from '/vercel/path0/markdoc/nodes/index.js'
const functions = {};
const schema = {
tags: defaultObject(tags),
nodes: defaultObject(nodes),
functions: defaultObject(functions),
...defaultObject(config),
};

const tokenizer = new Markdoc.Tokenizer();

/**
 * Source will never change at runtime, so parse happens at the file root
 */
const source = "---\ndescription: The geOps developer portal provides a toolbox for public transit applications. The tools comprise web services as well as open source libraries that have been inspired by many projects realized for public transit agencies.\nshowApis: top\nshowPageHero: true\nsidebar: licensing\n---\n\n## About the geOps Developer Portal\n\nOur tools support **extensive and precise information for passengers** on public transit networks and timetables including deviations and disruptions, much of it **in realtime**.\n\nAn important focus is on **spatial information** specially processed for the needs of public transport. Results are APIs for **maps that emphasize public transport** or APIs that **visualize the exact course** of routes or that track the positions of vehicles.\n";
const filepath = "/index.md";
const tokens = tokenizer.tokenize(source);
const ast = Markdoc.parse(tokens);

/**
 * Like the AST, frontmatter won't change at runtime, so it is loaded at file root.
 * This unblocks future features, such a per-page dataFetchingFunction.
 */
const frontmatter = ast.attributes.frontmatter
  ? yaml.load(ast.attributes.frontmatter)
  : {};

const {components, ...rest} = getSchema(schema)

export async function getStaticProps(context) {
  const partials = {};

  // Ensure Node.transformChildren is available
  Object.keys(partials).forEach((key) => {
    const tokens = tokenizer.tokenize(partials[key]);
    partials[key] = Markdoc.parse(tokens);
  });

  const cfg = {
    ...rest,
    variables: {
      ...(rest ? rest.variables : {}),
      // user can't override this namespace
      markdoc: {frontmatter},
      // Allows users to eject from Markdoc rendering and pass in dynamic variables via getServerSideProps
      ...(context.variables || {})
    },
    partials,
    source,
  };

  /**
   * transform must be called in dataFetchingFunction to support server-side rendering while
   * accessing variables on the server
   */
  const content = await Markdoc.transform(ast, cfg);

  return {
    // Removes undefined
    props: JSON.parse(
      JSON.stringify({
        markdoc: {
          content,
          frontmatter,
          file: {
            path: filepath
          }
        },
      })
    ),
  };
}

export default function MarkdocComponent(props) {
  // Only execute HMR code in development
  return renderers.react(props.markdoc.content, React, {
    components: {
      ...components,
      // Allows users to override default components at runtime, via their _app
      ...props.components,
    },
  });
}
